<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },

  async created() {
    await this.getAllInfoClr("profile");

    this.colores = this.profiles;
    this.actualizarColores();
  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),

    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
};
</script>

<style>
@import "./css/default.css";

@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Sora:wght@100;200;300;400;500;600;700;800&family=Source+Sans+Pro:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html {
  scroll-behavior: smooth;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #e21f1d;
  --color-2: #242424;
  --color-3: #a7a7a7;
  --color-4: #f5f5f5;
  --color-5: #ffffff;
  --color-6: #d9d9d9;
  --color-7: #ffffff;

  --Sans: "Source Sans Pro", sans-serif;
  --Open_Sans: "Open Sans", sans-serif;
  --Sora: "Sora", sans-serif;
  --Mukta: "Mukta", sans-serif;
  --Inter: "Inter", sans-serif;
  --Montserrat: "Montserrat", sans-serif;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
}

body {
  margin: 0vw;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

p {
  margin: 0;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

.dflx {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.tpblg{
  width: 8vw !important;
  margin-bottom: 1vw;
}

input#select-ch, input#select-ch1,input#select-ch2,input#select-ch3,input#select-ch4,input#select-ch5,input#select-ch6,input#select-ch7 {
  display: none;
}

.wdlrg{
  width: 22vw !important;
}

.tbl_items  {
  margin-top: 0.5vw;
    padding-bottom: 0.5vw;
}

.content_sections{
  padding-bottom: 5vw;
}


.searching-it {
  border: transparent !important;
  height: 1.5vw !important;
  margin: 0 !important;
}

.buscador-items {
  margin-left: 0;
  margin-top: 0.2vw;
  height: 1.5625vw;
  border: 0.052083vw solid var(--color-4);
  border-radius: 0.5vw;
  padding-left: 0.5vw;
}


.bodytbl-items {
  border: 0.052083vw solid var(--color-2);
  padding: 0.5vw;
  height: 5vw;
  font-size: .8vw;
  overflow: auto;
  background: var(--color-2);
  color: var(--color-5);
  margin: 1vw 0vw;
  border-radius: 0vw 0.5vw 0.5vw 0vw;
}

p.itemp{
  width: 90%;
  
}
.sp{

}
p.cp {
  cursor: pointer !important;
}


.VueCarousel-navigation-button {
  color: transparent !important;
  width: 6.146vw !important;
  height: 6.146vw !important;
  padding: 0 !important;
  background-image: url("assets/btn-s.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.VueCarousel-navigation-prev,
.VueCarousel-navigation-next {
  transform: translateY(0%) translateX(0%) !important;
}
.VueCarousel-navigation-prev {
  left: 3.698vw !important;
}
.VueCarousel-navigation-next {
  transform: rotate(180deg) !important;
  right: 3.698vw !important;
}
.VueCarousel-pagination {
  position: absolute;
  bottom: 2.24vw;
}
.VueCarousel-dot--active {
  background-color: #e33a23 !important;
}
#Projects .VueCarousel-navigation-button,
.projSec .VueCarousel-navigation-button {
  width: 3.385vw !important;
  height: 3.385vw !important;
  background-image: url("assets/btnred.svg");
}
#Projects .VueCarousel-navigation-prev,
.projSec .VueCarousel-navigation-prev {
  top: 13.854vw !important;
  left: 1.698vw !important;
}
#Projects .VueCarousel-navigation-next,
.projSec .VueCarousel-navigation-next {
  top: 13.854vw !important;
  transform: rotate(180deg) !important;
  right: 1.698vw !important;
}
#safety .VueCarousel-navigation-button,
.projSec .VueCarousel-navigation-button {
  width: 3.385vw !important;
  height: 3.385vw !important;
  background-image: url("assets/btnred.svg");
}
#safety .VueCarousel-navigation-prev,
.projSec .VueCarousel-navigation-prev {
  top: 13.854vw !important;
  left: 1.698vw !important;
}
#safety .VueCarousel-navigation-next,
.projSec .VueCarousel-navigation-next {
  top: 13.854vw !important;
  transform: rotate(180deg) !important;
  right: 1.698vw !important;
}
.s6 .VueCarousel-navigation-button {
  top: -7vw !important;
}
.s6 .VueCarousel-navigation-prev {
  left: 69.26vw !important;
}
.s6 .VueCarousel-navigation-button {
  width: 3.385vw !important;
  height: 3.385vw !important;
}
.s6 .VueCarousel-navigation-next {
  right: 1.146vw !important;
}
#SocialRes .VueCarousel-navigation-button {
  width: 3.385vw !important;
  height: 3.385vw !important;
  background-image: url("assets/left1.svg") !important;
  transition: 0.5s;
}
#SocialRes .VueCarousel-navigation-button:hover {
  background-image: url("assets/left1h.svg") !important;
}
#Review .VueCarousel-navigation-button {
  width: 3.385vw !important;
  height: 3.385vw !important;
  background-image: url("assets/left1.svg") !important;
}
#Review .VueCarousel-navigation-button:hover {
  background-image: url("assets/left1h.svg") !important;
}

#BlogC .VueCarousel-navigation-button,
#SocialRes .VueCarousel-navigation-button {
  background-image: url("assets/left1.svg") !important;
  z-index: 100;
}
#BlogC .modal-c p.cls,
#SocialRes .modal-c p.cls {
  color: #e33a23;
}
li.agile__dot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.563vw;
  height: 1.563vw;
  border: 0.052vw solid transparent;
  border-radius: 3vw;
  margin: 0 .12vw;
  transition: .4s;
}
li.agile__dot.agile__dot--current  { 
  border: 0.052vw solid white;
}
li.agile__dot button {
  width: 0.729vw;
  height: 0.729vw;
  border-radius: 5vw;
  background-color: #ffffff;
  border: none;
  padding: none;
  transition: .4s;
}
li.agile__dot.agile__dot--current button{
  background-color: #E33A23; 
}  
.agile__slide { 
    margin: auto;
    width: 15vw;
}
@media (min-width: 768px) {
  #SocialRes .VueCarousel-navigation-prev {
    left: 63.594vw !important;
  }
  #SocialRes .VueCarousel-navigation-next {
    left: 63.94vw !important;
    margin-top: -4.479vw !important;
  }
}

@media (min-width: 769px) {
  #BlogC .modal-c p.cls,
  #SocialRes .modal-c p.cls {
    font-size: 3.4vw;
    margin: 2vw 3vw 2.948vw auto;
    width: fit-content;
    cursor: pointer;
  }
  #BlogC .VueCarousel,
  #SocialRes .VueCarousel {
    width: 39.01vw;
    height: 27.813vw;
  }
  #BlogC .VueCarousel-navigation-button,
  #SocialRes .VueCarousel-navigation-button {
    color: transparent !important;
    width: 3.385vw !important;
    height: 3.385vw !important;
  }
  #BlogC .VueCarousel .VueCarousel-navigation-button,
  #SocialRes .VueCarousel .VueCarousel-navigation-button {
    top: 12.188vw;
    margin: 0 !important;
  }
  #BlogC .VueCarousel-navigation-prev,
  #SocialRes .VueCarousel-navigation-prev {
    left: 2.031vw !important;
    right: auto !important;
  }
  #BlogC .VueCarousel-navigation-next,
  #SocialRes .VueCarousel-navigation-next {
    right: 2.031vw !important;
    left: auto !important;
  }
  #BlogC .VueCarousel.cls23432 .VueCarousel-navigation-button,
  #SocialRes .VueCarousel.cls23432 .VueCarousel-navigation-button {
    filter: brightness(9.5);
  }
  #BlogC .modal-c .VueCarousel-navigation-prev,
  #SocialRes .modal-c .VueCarousel-navigation-prev {
    left: 0 !important;
    right: auto !important;
  }
  #BlogC .modal-c .VueCarousel-navigation-next,
  #SocialRes .modal-c .VueCarousel-navigation-next {
    right: 0 !important;
    left: auto !important;
  }
  #BlogC .modal-c .VueCarousel,
  #SocialRes .modal-c .VueCarousel {
    margin: auto;
  }
  #BlogC .modal-c .VueCarousel,
  #SocialRes .modal-c .VueCarousel {
    width: 80.01vw;
    height: 31.146vw;
  }
  .GF3 .VueCarousel-inner {
    flex-basis: 0vw !important;
  }
  .GF3 .VueCarousel-wrapper {
    width: 65.2vw;
    align-self: center;
  }

  .GF3 .VueCarousel-navigation-button {
    width: 3.3854166666666665vw !important;
    height: 3.3854166666666665vw !important;
  }

  .GF3 .VueCarousel-navigation {
    width: 4vw;
    align-self: center;
    margin-left: 2.2916666666666665vw;
    text-align-last: center;
  }

  .GF3 .VueCarousel-navigation-button {
    position: inherit !important;
    color: #e33a23 !important;
    border: 0.1vw solid #e33a23 !important;
    border-radius: 10vw !important;
  }
  .GF3 .VueCarousel {
    flex-direction: inherit !important;
    place-content: center;
    display: flex !important;
  }
  .GF3
    button.VueCarousel-navigation-button.VueCarousel-navigation-prev.VueCarousel-navigation--disabled {
    margin-bottom: 1.09375vw;
  }
}

@media (max-width: 768px) {
  /* */
  #BlogC .cls23432 .principal-img {
    width: 100%;
    height: 74vw;
    object-fit: cover;
  }
  #BlogC .modal-c p.cls,
  #SocialRes .modal-c p.cls {
    font-size: 8.4vw;
    margin: 2vw 5vw 0 auto;
    width: fit-content;
    cursor: pointer;
  }

  #BlogC .modal-c .VueCarousel,
  #SocialRes .modal-c .VueCarousel {
    width: 82vw;
    margin: 20vw auto 0;
  }
  #BlogC .VueCarousel-navigation,
  #SocialRes .VueCarousel-navigation {
    margin-top: 4vw;
  }
  #BlogC .VueCarousel-navigation-button {
    width: 15.7vw !important;
    height: 15.7vw !important;
  }
  #BlogC .VueCarousel-navigation-button {
    position: absolute;
    top: 100%;
  }
  #BlogC .VueCarousel-navigation-next {
    margin-top: 0 !important;
  }
  #BlogC .VueCarousel-navigation-prev {
    left: 18.698vw !important;
    right: auto !important;
  }
  #BlogC .VueCarousel-navigation-next {
    right: 18.698vw !important;
    left: auto !important;
  }
  #BlogC .VueCarousel-navigation {
    display: none;
  }
  #BlogC .modal-c .VueCarousel-navigation {
    display: block;
  }
  #SocialRes .modal-c .VueCarousel-navigation {
    display: block;
  }
  #Projects .VueCarousel-navigation-button,
  .projSec .VueCarousel-navigation-button,
  #SocialRes .VueCarousel-navigation-button {
    width: 16.385vw !important;
    height: 16.385vw !important;
    background-image: url("assets/btnred.svg");
  }
  #Projects .VueCarousel-navigation-prev,
  .projSec .VueCarousel-navigation-prev,
  #SocialRes .VueCarousel-navigation-prev {
    top: 99vw !important;
    left: 19vw !important;
  }
  #Projects .VueCarousel-navigation-next,
  .projSec .VueCarousel-navigation-next,
  #SocialRes .VueCarousel-navigation-next {
    top: 99vw !important;
    transform: rotate(180deg) !important;
    right: 19vw !important;
    margin: 0 !important;
  }
  #Projects .VueCarousel-wrapper,
  .projSec .VueCarousel-wrapper,
  #SocialRes .VueCarousel-wrapper {
    width: auto;
    height: 95vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #safety .VueCarousel-navigation-button,
  .projSec .VueCarousel-navigation-button,
  #SocialRes .VueCarousel-navigation-button {
    width: 16.385vw !important;
    height: 16.385vw !important;
    background-image: url("assets/btnred.svg");
  }
  #safety .VueCarousel-navigation-prev,
  .projSec .VueCarousel-navigation-prev,
  #SocialRes .VueCarousel-navigation-prev {
    top: 99vw !important;
    left: 19vw !important;
  }
  #safety .VueCarousel-navigation-next,
  .projSec .VueCarousel-navigation-next,
  #SocialRes .VueCarousel-navigation-next {
    top: 99vw !important;
    transform: rotate(180deg) !important;
    right: 19vw !important;
    margin: 0 !important;
  }
  #safety .VueCarousel-wrapper,
  .projSec .VueCarousel-wrapper,
  #SocialRes .VueCarousel-wrapper {
    width: auto;
    height: 95vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #SocialRes .slider_sr_mv .VueCarousel-wrapper {
    height: fit-content;
  }
  .VueCarousel-navigation-button {
    color: transparent !important;
    width: 12.56vw !important;
    height: 12.56vw !important;
  }
  .VueCarousel-navigation-prev {
    left: auto !important;
    right: 5.698vw !important;
  }
  .VueCarousel-navigation-next {
    margin-top: 17vw !important;
  }

  .GF3 .VueCarousel-inner {
    /*justify-content: center;*/
    text-align: -webkit-center;
  }

  .GF3 .VueCarousel-navigation-button {
    position: inherit !important;
    top: 0% !important;
  }
  .GF3 .VueCarousel-navigation-button {
    background-image: none !important;
  }
  .s7 .rev-c .VueCarousel-navigation {
    display: flex;
    justify-content: center;
  }
  #Review .VueCarousel-navigation-button {
    position: relative;
    width: 17.15vw !important;
    height: 17.15vw !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  #Review .VueCarousel-navigation-next {
    margin-top: 0 !important;
  }
}
</style>
