"use strict";


const cPost = 'f4p_HC4/42v-UHc'
const cGet = 'ayn_Rqv/WW9-AGv'
const cPut = '6su_DPB/toj-YBD'
const cDel = 'bpc_nzQ/GtU-TgA'


exports.endpointCodes  = {
      post: cPost,
      get: cGet,
      put: cPut,
      del: cDel
}


const local ='http://localhost:3677'
const cpanel ='https://control-e.com' // cambiar por la url del sitio web.

exports.url  =  cpanel + '/api/mFy_ebp/sYR-33o'; 

